<template>
  <section class="invoice-preview-wrapper">
    <b-row>
      <b-col sm="12" md="3" class="p-0 m-0">
        <navlink></navlink>
      </b-col>
      <b-col sm="12" md="9">
        <b-card>
          <b-card-body>
            <div>
              <div v-if="appData">
                <h3>Current Plan</h3>

                <table class="table">
                  <thead>
                    <tr>
                      <th style="width: 40%">Title</th>
                      <th>Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Name</td>
                      <td>{{ appData.data.name || "" }}</td>
                    </tr>
                    <tr>
                      <td>Cost</td>
                      <td>&#8377; {{ appData.data.data.cost || 0 }}
                    </td>
                    </tr>
                    <tr>
                      <td>Storage Size</td>
                      <td>{{ appData.data.data.sizegb }} gb</td>
                    </tr>
                    <tr>
                      <td>Duration</td>
                      <td>
                        <span v-if="appData.data.data.priceyear > 0"
                          >{{ appData.data.data.priceyear || 0 }} year </span
                        >
                        <span v-if="appData.data.data.pricemonths > 0"
                          >{{ appData.data.data.pricemonths || 0 }} months </span
                        >
                        <span v-if="appData.data.data.pricedate > 0"
                          >{{ appData.data.data.pricedate || 0 }} days </span
                        >
                      </td>
                    </tr>
                    <tr>
                      <td>Expiry on</td>
                      <td v-if="serviceprov">
                        {{ serviceprov ? serviceprov.data.expdate :'' }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import viewsidelist from "@/views/Component/viewsidelist.vue";
import {
  BAvatar,
  BRow,
  BCol,
  BAlert,
  BLink,
  BCardText,
  BCard,
  BTable,
  BTableLite,
  BCardBody,
  BForm,
  BButton,
} from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import Ripple from "vue-ripple-directive";
import StoreModule from "./StoreModule";
import useList from "./useList";
import { useToast } from "vue-toastification/composition";
import Logo from "@core/layouts/components/Logo.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import navlink from "../navlink.vue";
export default {
  components: {
    BCol,
    BCard,
    BAvatar,
    viewsidelist,
    BRow,
    BCardBody,
    BCardText,
    BForm,
    BTable,
    BCardBody,
    BTableLite,
    BButton,
    ToastificationContent,
    BAlert,
    BLink,
    Logo,
    navlink,
  },
  directives: {
    Ripple,
  },
  data() {
    return {};
  },
  setup(props, { emit }) {
    const GENAPP_APP_STORE_MODULE_NAME = "settings-profile";
    const modulename = "Profile";
    const modulenamesub = "profile";
    if (!store.hasModule(GENAPP_APP_STORE_MODULE_NAME))
      store.registerModule(GENAPP_APP_STORE_MODULE_NAME, StoreModule);

    var userData = JSON.parse(localStorage.getItem("userData"));

    onUnmounted(() => {
      if (store.hasModule(GENAPP_APP_STORE_MODULE_NAME))
        store.unregisterModule(GENAPP_APP_STORE_MODULE_NAME);
    });
    const toast = useToast();
    const appData = ref(null);
    const serviceprov = ref(null);

    const printview = () => {
      window.print();
    };
    const { deletefun } = useList();
    store
      .dispatch(GENAPP_APP_STORE_MODULE_NAME + "/pricing", {
        id: userData.pricing.id,
      })
      .then((response) => {
        if (!response.data) {
          toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              text: "Couldnt find " + modulenamesub + " details",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          router
            .push({
              name: GENAPP_APP_STORE_MODULE_NAME,
            })
            .catch(() => {});
        }
        appData.value = response.data.data;
        serviceprov.value = response.data.expdate;

      })
      .catch((error) => {
        if (error.response.status === 404) {
          appData.value = undefined;
          toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              text: "Couldnt find " + modulenamesub + " details",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        } else if (error.response.status === 500) {
          toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              text: "Input fields missing values",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        } else if (error.response.status === 422) {
          toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              text: "Please refresh your page",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        }
        // router
        //   .push({
        //     name: GENAPP_APP_STORE_MODULE_NAME,
        //   })
        //   .catch(() => {});
      });

    return {
      GENAPP_APP_STORE_MODULE_NAME,
      deletefun,
      modulename,
      modulenamesub,
      appData,
      printview,
      serviceprov,
      userData,
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {
  a {
    color: black;
    text-decoration: none !important;
  }

  .d-print-block {
    display: block !important;
  }

  .content-header-right {
    display: none;
  }

  .invoice-actions {
    display: none;
  }

  .breadcrumbs-top {
    display: none;
  }

  body {
    background-color: transparent !important;
  }

  nav.header-navbar {
    display: none;
  }

  .main-menu {
    display: none;
  }

  .header-navbar-shadow {
    display: none !important;
  }

  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }

  footer.footer {
    display: none;
  }

  .card {
    background-color: transparent;
    box-shadow: none;
  }

  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-wipricing: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-wipricing: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }
  }
}
</style>
